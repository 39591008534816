@import "https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap";
.Jyl0gq_dialogOverlay {
  -webkit-backdrop-filter: blur(40px);
  background: #00000026;
  animation: .15s cubic-bezier(.16, 1, .3, 1) Jyl0gq_overlayShow;
  position: fixed;
  inset: 0;
}

.Jyl0gq_dialogContent {
  background-color: var(--color-white);
  border-radius: 6px;
  outline: none;
  width: auto;
  max-width: 450px;
  max-height: 85vh;
  animation: .15s cubic-bezier(.16, 1, .3, 1) Jyl0gq_contentShow;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Jyl0gq_dialogContent:focus {
  outline: none;
}

@keyframes Jyl0gq_overlayShow {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes Jyl0gq_contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%)scale(.96);
  }

  to {
    opacity: 1;
    transform: translate(-50%, -50%)scale(1);
  }
}

.Jyl0gq_infoText {
  color: var(--color-gray);
  margin: 0;
  padding: 0;
}

.Jyl0gq_form {
  gap: 12px;
  display: flex;
}

.Jyl0gq_calendarWrapper {
  background: var(--color-white);
  -webkit-appearance: none;
  border-radius: 4px;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  display: flex;
  box-shadow: 0 10px 38px -10px #0e121659, 0 10px 20px -15px #0e121633;
}

.Jyl0gq_wrapper {
  border-radius: 4px;
  padding: 16px;
  display: inline-flex;
}

@media (width <= 768px) {
  .Jyl0gq_calendarCard {
    margin-bottom: 0;
  }

  .Jyl0gq_infoText {
    z-index: 1;
    padding: 16px;
    box-shadow: 0 -4px 16px #00000017;
  }

  .Jyl0gq_form {
    flex-direction: column;
  }

  .Jyl0gq_calendarContainer {
    min-height: 400px;
    max-height: 70vh;
    padding: 16px;
    overflow-y: auto;
  }

  .Jyl0gq_calendarWrapper {
    gap: 0;
    width: 98vw;
    max-width: 450px;
    padding: 0;
  }
}

.Jyl0gq_submit {
  all: unset;
  background: var(--color-gray);
  color: var(--color-white);
  cursor: pointer;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  min-width: 101px;
  height: 49px;
  padding: 0 24px 0 12px;
  font-size: 15px;
  font-weight: 500;
  display: flex;
}

.Jyl0gq_submit svg {
  width: 18px;
  height: 18px;
}

.Jyl0gq_errorForm {
  gap: 64px;
}

.Jyl0gq_errorForm button.Jyl0gq_submit {
  padding: 0 24px;
}

.Jyl0gq_errorMessageTitle {
  color: var(--color-white);
  font-size: 20px;
}

.Jyl0gq_errorMessageDescription {
  color: var(--color-lilac);
  font-size: 16px;
}

@media (width <= 768px) {
  .Jyl0gq_errorForm {
    gap: 12px;
    max-width: 220px;
  }

  .Jyl0gq_errorMessage {
    flex-direction: column;
    gap: 12px;
    display: flex;
  }

  .Jyl0gq_errorMessageDescription {
    margin-bottom: 24px;
  }
}

* {
  --color-lilac: #bdb2c3;
  --color-lilac-dark: #545166;
  --color-white: #fff;
  --color-gray: #46454c;
  --color-gray-light: #f0f0f3;
  --color-panel: #fffc;
  --color-gray-a2: #00000006;
  --color-gray-a5: #0000001f;
  --color-black-a2: #0000001a;
  --color-gray-semi: #636363;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

.fancybox__container {
  pointer-events: auto;
}

@font-face {
  font-family: Main;
  src: url("main.6ab45b66.otf");
}

* {
  font-family: Main, Roboto, sans-serif;
}

.lv-booking-module {
  z-index: 100000;
  border: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.BG7B0W_wrapper {
  background: var(--color-white);
  cursor: pointer;
  border-radius: 8px;
  gap: 12px;
  width: auto;
  min-width: 220px;
  padding: 8px;
  display: inline-flex;
}

.BG7B0W_group {
  flex-direction: column;
  width: 100%;
  display: flex;
}

.BG7B0W_icon {
  display: flex;
}

.BG7B0W_icon svg {
  width: 16px;
  height: 16px;
  margin: auto;
}

.BG7B0W_icon svg * {
  fill: var(--color-lilac-dark);
}

.BG7B0W_label {
  color: var(--color-lilac-dark);
  font-size: 12px;
}

.BG7B0W_input {
  cursor: pointer;
  color: var(--color-lilac-dark);
  white-space: nowrap;
  background: none;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 500;
  overflow: hidden;
}

@keyframes BG7B0W_spin {
  100% {
    transform: rotate(360deg);
  }
}

.BG7B0W_loader {
  color: var(--color-lilac);
  align-items: center;
  gap: 8px;
  min-width: 154px;
  height: 19px;
  font-size: 13px;
  display: flex;
}

.BG7B0W_loader svg {
  width: 19px;
  height: 19px;
  animation: 1.5s linear infinite BG7B0W_spin;
}

.kmCbVa_stepper::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.kmCbVa_stepper::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.kmCbVa_stepper {
  -moz-appearance: textfield;
  text-align: center;
  -webkit-appearance: none;
  box-shadow: inset 0 0 0 1px var(--color-gray-a5), inset 0 1.5px 2px 0 var(--color-gray-a2), inset 0 1.5px 2px 0 var(--color-black-a2);
  -webkit-box-shadow: inset 0 0 0 1px var(--color-gray-a5), inset 0 1.5px 2px 0 var(--color-gray-a2), inset 0 1.5px 2px 0 var(--color-black-a2);
  -moz-appearance: textfield;
  border: none;
  border-radius: 4px;
  outline: none;
  width: 111px;
  height: 35px;
  padding: 0 16px;
  font-size: 13px;
  line-height: 1;
}

.kmCbVa_button {
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
  z-index: 0;
  border: 8px;
  place-items: center;
  width: 24px;
  height: 24px;
  margin: 0;
  padding: 0;
  display: grid;
  position: absolute;
}

.kmCbVa_button.kmCbVa_disabled {
  color: var(--color-gray-light);
}

.kmCbVa_minus {
  top: calc(50% - 12px);
  left: 8px;
}

.kmCbVa_plus {
  top: calc(50% - 12px);
  right: 8px;
}

.kmCbVa_wrapper {
  display: flex;
  position: relative;
}

.syB1rG_wrapper {
  -webkit-appearance: none;
  background: var(--color-white);
  border-radius: 4px;
  flex-direction: column;
  gap: 16px;
  min-width: 300px;
  padding: 12px;
  display: flex;
  box-shadow: 0 10px 38px -10px #0e121659, 0 10px 20px -15px #0e121633;
}

.syB1rG_rooms {
  flex-direction: column;
  gap: 32px;
  max-height: 480px;
  padding-right: 4px;
  display: flex;
  overflow: auto;
}

.syB1rG_rooms::-webkit-scrollbar {
  width: 4px;
  padding-left: 4px;
}

.syB1rG_rooms::-webkit-scrollbar-track {
  background: var(--color-lilac);
}

.syB1rG_rooms::-webkit-scrollbar-thumb {
  background: var(--color-lilac-dark);
}

.syB1rG_title {
  font-size: 18px;
  font-weight: 500;
}

.syB1rG_room, .syB1rG_add {
  --s: 16px;
  --t: 1px;
  --g: 16px;
  padding: calc(var(--g)  + var(--t));
  outline: var(--t) solid var(--color-gray);
  outline-offset: calc(-1 * var(--t));
  -webkit-mask: conic-gradient(at var(--s) var(--s), #0000 75%, #000 0) 0 0 / calc(100% - var(--s)) calc(100% - var(--s)), linear-gradient(#000 0 0) content-box;
}

.syB1rG_room {
  flex-direction: column;
  gap: 16px;
  display: flex;
}

.syB1rG_roomHead {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.syB1rG_roomBody {
  flex-direction: column;
  gap: 16px;
  display: flex;
}

.syB1rG_inputs {
  gap: 16px;
  display: flex;
}

.syB1rG_subtitle {
  font-size: 16px;
  font-weight: 500;
}

.syB1rG_label {
  color: var(--color-lilac-dark);
  margin-bottom: 2px;
  font-size: 13px;
}

.syB1rG_add {
  cursor: pointer;
  opacity: .5;
  background: none;
  border: none;
  justify-content: center;
  gap: 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  transition: all .2s;
  display: flex;
}

.syB1rG_add svg {
  width: 20px;
  height: 20px;
}

.syB1rG_add:hover {
  opacity: 1;
}

.syB1rG_trash {
  cursor: pointer;
  background: none;
  border: none;
  width: 20px;
  height: 20px;
  margin: 0;
  padding: 0;
}

.syB1rG_trash svg {
  width: 20px;
  height: 20px;
}

.syB1rG_group {
  width: 100%;
}

.syB1rG_selects {
  flex-direction: column;
  gap: 16px;
  display: flex;
}

.syB1rG_buttons {
  display: flex;
}

.FjSCqq_trigger {
  all: unset;
  box-sizing: border-box;
  color: var(--color-gray);
  -webkit-appearance: none;
  box-shadow: inset 0 0 0 1px var(--color-gray-a5), inset 0 1.5px 2px 0 var(--color-gray-a2), inset 0 1.5px 2px 0 var(--color-black-a2);
  -webkit-box-shadow: inset 0 0 0 1px var(--color-gray-a5), inset 0 1.5px 2px 0 var(--color-gray-a2), inset 0 1.5px 2px 0 var(--color-black-a2);
  cursor: pointer;
  background-color: #fff;
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  width: 100%;
  height: 35px;
  padding: 0 16px;
  font-size: 13px;
  line-height: 1;
  display: inline-flex;
}

.FjSCqq_trigger:hover {
  background-color: var(--color-gray-light);
}

.FjSCqq_trigger[data-placeholder] {
  color: var(--color-lilac);
}

.FjSCqq_icon {
  color: var(--color-gray);
}

.FjSCqq_content {
  -webkit-appearance: none;
  width: var(--radix-select-trigger-width);
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 10px 38px -10px #16171859, 0 10px 20px -15px #16171833;
}

.FjSCqq_viewport {
  padding: 5px;
}

.FjSCqq_item {
  color: var(--color-lilac-dark);
  -webkit-user-select: none;
  user-select: none;
  border-radius: 3px;
  align-items: center;
  height: 25px;
  padding: 0 35px 0 25px;
  font-size: 13px;
  line-height: 1;
  display: flex;
  position: relative;
}

.FjSCqq_item[data-highlighted] {
  background-color: var(--color-lilac-dark);
  color: var(--color-white);
  outline: none;
}

.FjSCqq_label {
  padding: 0 25px;
  font-size: 12px;
  line-height: 25px;
}

.FjSCqq_separator {
  height: 1px;
  margin: 5px;
}

.FjSCqq_itemIndicator {
  justify-content: center;
  align-items: center;
  width: 25px;
  display: inline-flex;
  position: absolute;
  left: 0;
}

.FjSCqq_scrollButton {
  cursor: default;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  height: 25px;
  display: flex;
}

.FjSCqq_triggerError {
  border: 1px solid red;
}

.FjSCqq_error {
  color: red;
  margin-top: 2px;
  font-size: 13px;
}

._3IVTJG_button {
  all: unset;
  box-sizing: border-box;
  background: var(--color-gray);
  cursor: pointer;
  color: var(--color-white);
  border-radius: 4px;
  align-items: center;
  gap: 4px;
  height: 35px;
  margin-left: auto;
  padding: 0 15px;
  font-size: 13px;
  transition: color .2s, background .2s;
  display: flex;
}

._3IVTJG_button:hover {
  background: var(--color-lilac-dark);
}

._3IVTJG_gray {
  background: var(--color-gray-light);
  color: var(--color-gray);
}

._3IVTJG_gray:hover {
  background: var(--color-white);
  color: #000;
}

._3IVTJG_dark {
  background: var(--color-gray);
  color: var(--color-gray-light);
}

._3IVTJG_dark:hover {
  background: var(--color-gray);
  color: var(--color-white);
}

._3IVTJG_medium {
  height: auto;
  padding: 12px 18px;
  font-size: 15px;
}

.EIJiCq_weeks {
  grid-template-columns: repeat(7, 1fr);
  display: grid;
}

.EIJiCq_week {
  text-transform: uppercase;
  color: var(--color-gray);
  place-items: center;
  margin: 12px 0;
  font-size: 12px;
  font-weight: 300;
  display: grid;
}

.EIJiCq_week:nth-child(6n), .EIJiCq_week:nth-child(7n) {
  color: red;
}

.EIJiCq_days {
  grid-template-columns: repeat(7, 1fr);
  display: grid;
}

.EIJiCq_months {
  gap: 12px;
  display: flex;
}

.EIJiCq_day {
  cursor: pointer;
  color: var(--color-gray);
  -webkit-appearance: none;
  -webkit-box-shadow: 1px 1px 0 0 var(--color-lilac), inset 1px 0 0 0 var(--color-lilac), inset 0 1px 0 0 var(--color-lilac);
  box-shadow: 1px 1px 0 0 var(--color-lilac), inset 1px 0 0 0 var(--color-lilac), inset 0 1px 0 0 var(--color-lilac);
  box-sizing: border-box;
  background: none;
  border: none;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 60px;
  height: 60px;
  padding: 10px 6px 6px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  transition: background .2s;
  display: flex;
  position: relative;
}

.EIJiCq_day svg {
  width: 21px;
  height: 21px;
}

.EIJiCq_monthName {
  text-align: center;
  text-transform: capitalize;
  margin: 12px 0;
  font-size: 16px;
  font-weight: 500;
}

.EIJiCq_container {
  position: relative;
}

.EIJiCq_arrows {
  justify-content: space-between;
  width: 100%;
  display: flex;
  position: absolute;
}

.EIJiCq_arrows button {
  all: unset;
  cursor: pointer;
  color: var(--color-gray);
  padding: 12px;
}

.EIJiCq_arrows button svg {
  width: 19px;
  height: 19px;
}

.EIJiCq_arrows button:disabled {
  color: var(--color-lilac);
}

.EIJiCq_wrapper {
  background: var(--color-white);
  -webkit-appearance: none;
  border-radius: 4px;
  flex-direction: column;
  gap: 24px;
  max-width: 900px;
  padding: 24px;
  display: flex;
  box-shadow: 0 10px 38px -10px #0e121659, 0 10px 20px -15px #0e121633;
}

.EIJiCq_hovered {
  background: var(--color-lilac-dark);
  color: var(--color-white);
}

.EIJiCq_from, .EIJiCq_to, .EIJiCq_inRange {
  background: var(--color-gray);
  color: var(--color-white);
}

.EIJiCq_disabled {
  cursor: default;
  color: var(--color-lilac);
  box-shadow: none;
  -webkit-box-shadow: none;
}

.EIJiCq_cheapest:not(.EIJiCq_from):not(.EIJiCq_to):not(.EIJiCq_inRange):not(.EIJiCq_hovered) {
  color: var(--color-lilac-dark);
}

.EIJiCq_cheapest:not(.EIJiCq_from):not(.EIJiCq_to):not(.EIJiCq_inRange):not(.EIJiCq_hovered) span {
  color: #3bbf56;
}

@keyframes EIJiCq_spin {
  100% {
    transform: rotate(360deg);
  }
}

.EIJiCq_loading svg {
  animation: 3s linear infinite EIJiCq_spin;
}

@media (width >= 769px) {
  .EIJiCq_day:hover, .EIJiCq_cheapest:not(.EIJiCq_from):not(.EIJiCq_to):not(.EIJiCq_inRange):not(.EIJiCq_hovered):hover {
    background: var(--color-lilac-dark);
    color: var(--color-white);
  }

  .EIJiCq_cheapest:not(.EIJiCq_from):not(.EIJiCq_to):not(.EIJiCq_inRange):not(.EIJiCq_hovered):hover span {
    color: var(--color-white);
  }

  .EIJiCq_disabled:hover {
    color: var(--color-lilac);
    background: none;
  }

  .EIJiCq_inRange:hover, .EIJiCq_to:hover, .EIJiCq_from:hover {
    background: var(--color-gray);
    color: var(--color-white);
  }
}

.EIJiCq_infoText {
  color: var(--color-gray);
  margin: 0;
  padding: 0;
  font-size: 18px;
}

.EIJiCq_description {
  color: var(--color-gray);
  margin-top: 8px;
  font-size: 14px;
  font-weight: 300;
}

.EIJiCq_title {
  color: var(--color-gray);
  font-size: 18px;
  font-weight: 500;
}

.EIJiCq_day span {
  white-space: nowrap;
  font-size: 10px;
  font-weight: 400;
}

@media (width <= 988px) {
  .EIJiCq_day {
    width: 50px;
    height: 50px;
    padding: 8px 4px 4px;
    font-size: 12px;
  }

  .EIJiCq_wrapper {
    max-width: 760px;
  }

  .EIJiCq_day span {
    font-size: 9px;
  }
}

@media (width <= 768px) {
  .EIJiCq_wrapper {
    min-height: 400px;
    max-height: 80vh;
    padding: 16px;
  }

  .EIJiCq_container {
    padding: 0 1px 1px 0;
    overflow-y: auto;
  }

  .EIJiCq_day {
    width: 50px;
    height: 50px;
    font-size: 12px;
  }

  .EIJiCq_months {
    flex-direction: column;
  }

  .EIJiCq_day svg {
    width: 18px;
    height: 18px;
  }

  .EIJiCq_arrows {
    display: none;
  }
}

.EIJiCq_popover {
  color: var(--color-black);
  z-index: 1;
  background: var(--color-white);
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  width: 160px;
  padding: 6px;
  font-size: 12px;
  font-weight: 300;
  display: none;
  position: absolute;
  top: 0;
  transform: translateY(calc(-100% - 6px));
}

.EIJiCq_popover:before {
  content: "";
  background: var(--color-white);
  content: "";
  background: var(--color-white);
  border: 1px solid #e6e6e6;
  border-radius: 1px;
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: -6px;
  left: calc(50% - 6px);
  transform: rotate(45deg);
  box-shadow: 0 1px 14px #0003;
}

.EIJiCq_popover:after {
  content: attr(data-text);
  background: var(--color-white);
  color: var(--color-gray);
  border-radius: 4px;
  padding: 6px;
  position: absolute;
  top: 0;
  left: 0;
}

.EIJiCq_day:hover .EIJiCq_popover {
  display: block;
}

.EIJiCq_beforeTo {
  opacity: .5;
}

.EIJiCq_beforeMin, .EIJiCq_afterMax, .EIJiCq_departureDisabled {
  opacity: .3 !important;
}
/*# sourceMappingURL=widjet.cb4b7b68.css.map */
